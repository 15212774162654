import React, {useEffect} from 'react'
import './EngineeringPage.css'

import Header from '../../components/header/Header'
import Details from '../../components/details/Details'
import Footer from '../../components/footer/Footer'

import { Col, Container, Row } from 'react-bootstrap'

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const EngineeringPage = () => { useEffect(() => {
  AOS.init();
 }, []);

  return (
    <div>
      <Header/>
      <div id='about-content'>
      <h1 className='title'>Engineering</h1>
       
       <Container>
         <Row>
           

           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/bim.jpg')} alt="bim" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col  md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>BIM</h2>
           <p>Building Information Modeling is a workflow process. It's based around models used for the planning, design, construction, and management of building and infrastructure projects. BIM software is used to model and optimize projects by planning, designing, building, and operating BIM models.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/mep.jpg')} alt="mep" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>MEP</h2>
           <p>MEP engineering is the science and art of planning, designing and managing the MEP systems of a building.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/revit.jpg')} alt="revit" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Revit</h2>
           <p>Revit is a commercial building information modeling (BIM) software by the company Autodesk. It's generally used by architects, structural engineers, mechanical, electrical, and plumbing (MEP) engineers, designers, and contractors.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/r-e.jpg')} alt="r-e" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Reverse Engineering</h2>
           <p>Reverse engineering, sometimes called back engineering, is a process in which software, machines, aircraft, architectural structures and other products are deconstructed to extract design information from them.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/cad.png')} alt="CAD" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>3D & 2D CAD</h2>
           <p>2D CAD is two-dimensional computer-aided design, 3D CAD is three-dimensional computer-aided design.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/engineering/o-g.jpg')} alt="orthophoto" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Oil and Gas</h2>
           <p>Oil and gas engineering refers to the design, implementation and operation of technical processes focused on the exploration and production of crude oil and natural gas.</p>
           </div>
           </Col>
           <div className='gap'></div>

         </Row>
       </Container>

       </div>
        
      <Details/>
      <Footer/>
    </div>
  )
}

export default EngineeringPage