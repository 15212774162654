import React, {useState} from 'react'
import './Ours.css'

import { IoEyeOutline } from "react-icons/io5";
import { TbTargetArrow } from "react-icons/tb";
import { FaHandHoldingHeart } from "react-icons/fa";



const Ours = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }


  return (
    <div>
            <div className='tab-container'>
        <nav className='intro-box'>
            <button onClick={() => toggleTab(1)} className={toggleState === 1 ? "tabs active-tabs" : "tabs"}><IoEyeOutline className='our-icons'/>Our Vision</button>
            <button onClick={() => toggleTab(2)} className={toggleState === 2 ? "tabs active-tabs" : "tabs"}><TbTargetArrow className='our-icons'/>Our Mission</button>
            <button onClick={() => toggleTab(3)} className={toggleState === 3 ? "tabs active-tabs" : "tabs"}><FaHandHoldingHeart className='our-icons'/>Our Value</button>
        </nav>

        <div className='content-tabs'>
            <div className={toggleState === 1 ? "content active-content" : "content"}>
                <p>Our vision is to create a synergic effect on the Geospatial and Engineering Industry by envisioning and creating convergent solutions that imbibe the evolving technologies across Digital Photogrammetry, LIDAR, GIS, And ORTHO Photo with our dynamism, specialization and expertise in Earth Sciences, Engineering and Management.
</p>
            </div>

            <div className={toggleState === 2 ? "content active-content" : "content"}>
                <p>We will supply leading edge solutions that incorporate the latest breakthroughs in algorithms and processing technologies to bring our customers new levels of enterprise production solutions that improve their bottom line profitability. To achieve completeness and accuracy of each map product, the company uses specific quality control measures that incorporate the use of verification software, operator verification, and independent editing</p>
            </div>

            <div className={toggleState === 3 ? "content active-content" : "content"}>
                <p>Our values define who we are. They are the fundamental beliefs of our global organization. They guide our actions and behavior. They influence the way we work with each other – and the way we serve our clients and engage with our communities.</p>
            </div>
        
        </div>
        </div>
    </div>
  )
}

export default Ours