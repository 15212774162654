import React from "react";
import "./Details.css";

import { IoCall } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { FaBusinessTime } from "react-icons/fa";

import BgImageTwo from '../../assets/images/bg/background2.webp'
import { Parallax, Background } from 'react-parallax'

const Details = () => {
  return (
    <div id="contact">
      <Parallax strength={400}>
        <Background className="custom-bg-two">
            <img src={BgImageTwo} alt='backgroundimage'/>
        </Background>
      <div className="contact-details">
        <a href="tel:7845174301">
          <div className="details">
            <IoCall className="detail-icon" />
            <p>91+ 784-517-4301</p>
          </div>
        </a>

        <a href="mailto:info@geostorm.in">
          <div className="details">
            <IoMailOutline className="detail-icon" />
            <p>info@geostorm.in</p>
          </div>
        </a>

      </div>
      <address className="detailss">
          <IoLocationSharp className="detail-icon" />
          <p>
            No.4, ground floor, Ganga nagar, 3rd Street, Jafferkhanpet, Chennai - 600083
          </p>
        </address>

        <div className="detailss">
        <FaBusinessTime className="detail-icon" /><p>Working days - Monday to Saturday |
        Opening hours - 9.30 to 7.00</p>
       </div>
       </Parallax>
    </div>
  );
};

export default Details;
