import React, {useEffect} from 'react'
import "./AboutPage.css"


import Header from '../../components/header/Header'
import Details from '../../components/details/Details'
import Footer from '../../components/footer/Footer'


import { BiSolidUserDetail } from "react-icons/bi";
import { MdOutlineHistory } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { TbTargetArrow } from "react-icons/tb";
import { FaHandHoldingHeart } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { BsBuildingFillCheck } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const AboutPage = () => { useEffect(() => {
  AOS.init()
}, [])

  return (
    <div>
      <Header/>
      <div id='about-container'>

      <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><FaQuestionCircle className="about-icons" />Why choose our Company?</h2>
        <p data-aos="fade-up" data-aos-duration="1000"><b>GEO STORM TECH</b> strength is in its core of competent,
highly qualified, trained and experienced surveyors,
geoscientists, and skilled computer and software
professionals known for development of innovative
programmed to provide cutting edge technology in
GIS based services. The team of our members are
drawn from the best institutions in India & overseas
of various disciplines.<br/><br/>
<GiCheckMark /> Virtual Extended Arm of the Customer.<br/><br/>
<GiCheckMark /> Significant Reduction of Product Lifecycle Time
and Cost.<br/><br/>
<GiCheckMark /> Innovative solutions.<br/><br/>
<GiCheckMark /> Quality Assurance and timely delivery.<br/><br/>
<GiCheckMark /> Proven Geospatial Domain expertise.<br/><br/>
<GiCheckMark /> Robust Security& business continuity.</p>

<h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><BsBuildingFillCheck className="about-icons" />Infrastructure</h2>
        <p data-aos="fade-up" data-aos-duration="1000"><b>GEO STORM TECH</b> Offices are well
equipped with state-of-the-art
computing, networking, security and
communication infrastructure. The
offices are also provided with facilities
such as fully fledged conference rooms
and Audio conferencing facilities and
complete power and system back up to
operate 24*7.</p>

        <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><BiSolidUserDetail className="about-icons" />Who we are</h2>
        <p data-aos="fade-up" data-aos-duration="1000"><b>GEO STORM TECH</b>, based in India, is a consultancy and
              Implementation solution provider in the fields of geospatial
              technologies, Engineering and Survey sector. We provide quality
              service in GIS mapping, Data management, Remote sensing,
              Photogrammetry mapping, Image processing, LIDAR and Ortho Photo.
              <br />
              <br />
              Our cost-effective solutions cater to the broad spectrum of
              Government and Private Users. We have clear vision to provide best
              class services to our clients under the guidance of our
              experienced and professional management team.
              <br />
              <br />
              We have executed more than 500 projects across the world wide.
              People Partnership Success - reflects the way we approach our
              business. The expertise of our people, the long-term client
              relationships we form, the active participation within our
              industry and the strategic partnerships we've created over the
              years combine to help build successful solutions that add to the
              value of your organization. Excellent client relationships
              underpins the success of every solution we provide. We strive to
              provide solutions that best address your needs as successful
              projects are crucial to our company growth.</p>


              <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><MdOutlineHistory className="about-icons" />Our History</h2>
        <p data-aos="fade-up" data-aos-duration="1000"><b>GEO STORM TECH</b>, has been working in the geospatial sector, offering mapping and surveying services to its prestigious clients in India and beyond. In a timely manner, we have been effectively producing data that meets client expectations.<br/><br/>
              Our team understands the value, acquisition, and analysis of geospatial mapping data and how it is applied to meet the needs and challenges of our valuable clients.<br/><br/>
              <b>GEO STORM TECH</b> is with you at every stage of mapping and managing your underground infrastructure.<br/><br/>
              Saves time and leads to faster results. Existing records are acquired to identify an approximate location, ascertain the materials used to construct the lines and improve the overall organization and Productivity of locating team.</p>

              <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><IoEyeOutline className="about-icons" />Our Vision</h2>
        <p data-aos="fade-up" data-aos-duration="1000">Our vision is to create a synergic effect on the Geospatial and Engineering Industry by envisioning and creating convergent solutions that imbibe the evolving technologies across Digital Photogrammetry, LIDAR, GIS, And ORTHO Photo with our dynamism, specialization and expertise in Earth Sciences, Engineering and Management.</p>

              <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><TbTargetArrow className="about-icons" />Our Mission</h2>
        <p data-aos="fade-up" data-aos-duration="1000">We will supply leading edge solutions that incorporate the latest breakthroughs in algorithms and processing technologies to bring our customers new levels of enterprise production solutions that improve their bottom line profitability. To achieve completeness and accuracy of each map product, the company uses specific quality control measures that incorporate the use of verification software, operator verification, and independent editing</p>

              <h2 className='about-title' data-aos="fade-up" data-aos-duration="500"><FaHandHoldingHeart className="about-icons" />Our Value</h2>
        <p data-aos="fade-up" data-aos-duration="1000">Our values define who we are. They are the fundamental beliefs of our global organization. They guide our actions and behavior. They influence the way we work with each other – and the way we serve our clients and engage with our communities.</p>

      </div>
      <Details/>
      <Footer/>
    </div>
  )
}

export default AboutPage