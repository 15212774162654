import React from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Banner from '../components/banner/Banner';
import Introduction from '../components/introduction/Introduction';
import Ours from '../components/ours/Ours';
import Services from '../components/services/Services';

import Details from '../components/details/Details';

const Home = () => {
  return (
    <div>
      <Header/>
      <Banner/>
      <Services/>
      <Introduction/>
      <Ours/>
      
      <Details/>
      <Footer/>
    </div>
  )
}

export default Home