import React, { useState } from "react";
import "./Introduction.css";

import { BiSolidUserDetail } from "react-icons/bi";
import { MdOutlineHistory } from "react-icons/md";

const Introduction = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div>
      <div className="tab-container">
        <nav className="intro-box">
          <button
            onClick={() => toggleTab(1)}
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          >
            <BiSolidUserDetail className="our-icons" />
            Who we are
          </button>
          <button
            onClick={() => toggleTab(2)}
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          >
            <MdOutlineHistory className="our-icons" />
            Our History
          </button>
        </nav>

        <div className="content-tabs">
          <div
            className={toggleState === 1 ? "content active-content" : "content"}
          >
            <p>
              <b>GEO STORM TECH</b>, based in India, is a consultancy and
              Implementation solution provider in the fields of geospatial
              technologies, Engineering and Survey sector. We provide quality
              service in GIS mapping, Data management, Remote sensing,
              Photogrammetry mapping, Image processing, LIDAR and Ortho Photo.
              <br />
              <br />
              Our cost-effective solutions cater to the broad spectrum of
              Government and Private Users. We have clear vision to provide best
              class services to our clients under the guidance of our
              experienced and professional management team.
              <br />
              <br />
              We have executed more than 500 projects across the world wide.
              People Partnership Success - reflects the way we approach our
              business. The expertise of our people, the long-term client
              relationships we form, the active participation within our
              industry and the strategic partnerships we've created over the
              years combine to help build successful solutions that add to the
              value of your organization. Excellent client relationships
              underpins the success of every solution we provide. We strive to
              provide solutions that best address your needs as successful
              projects are crucial to our company growth.
            </p>
          </div>

          <div
            className={toggleState === 2 ? "content active-content" : "content"}
          >
            <p>
            <b>GEO STORM TECH</b>, has been working in the geospatial sector, offering mapping and surveying services to its prestigious clients in India and beyond. In a timely manner, we have been effectively producing data that meets client expectations.<br/><br/>
              Our team understands the value, acquisition, and analysis of geospatial mapping data and how it is applied to meet the needs and challenges of our valuable clients.<br/><br/>
              Geo Storm Tech is with you at every stage of mapping and managing your underground infrastructure.<br/><br/>
              Saves time and leads to faster results. Existing records are acquired to identify an approximate location, ascertain the materials used to construct the lines and improve the overall organization and Productivity of locating team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
