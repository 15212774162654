import React, { useEffect }  from "react";
import "./Services.css";
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();


const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div id="cards_landscape_wrap-2">
        <Container>
          <Row>
              <h2>OUR SERVICES</h2>

            <Col lg={4} md={6} sm={12} data-aos="zoom-out-up" data-aos-duration="1000">
              <Link to = "/geospatial">
                <div className="card-flyer">
                  <div className="text-box">
                    <div className="image-box">
                    <img src = {require ('../../assets/images/services/geospatial.jpg')}
             alt='geospatial'/>
                    </div>
                    <div className="text-container">
                      <h6>GEOSPATIAL</h6>
                      <p>
                      <ul>
                  <li>Photogrammetery</li>
                  <li>LiDAR</li>
                  <li>Mobile LiDar</li>
                  <li>GIS</li>
                  <li>CAD</li>
                  <li>Orthophoto</li>
                  <li>Cadastral Mapping</li>
                </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12} data-aos="zoom-out-up" data-aos-duration="1000">
              <Link to = "/engineering">
                <div className="card-flyer">
                  <div className="text-box">
                    <div className="image-box">
                    <img src = {require ('../../assets/images/services/engineering.jpg')}
             alt='engineering'/>
                    </div>
                    <div className="text-container">
                      <h6>ENGINEERING</h6>
                      <p>
                      <ul>
                  <li>BIM</li>
                  <li>MEP</li>
                  <li>Revit</li>
                  <li>Reverse Engineering</li>
                  <li>3D & 2D CAD</li>
                  <li>Oil & Gas</li><br/>
                </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col lg={4} md={6} sm={12} data-aos="zoom-out-up" data-aos-duration="1000">
              <Link to = "/surveying">
                <div className="card-flyer">
                  <div className="text-box">
                    <div className="image-box">
                    <img src = {require ('../../assets/images/services/survey.jpg')}
             alt='surveying'/>
                    </div>
                    <div className="text-container">
                      <h6>SURVEYING</h6>
                      <p>
                      <ul>
                  <li>DGPS</li>
                  <li>Total Station</li>
                  <li>Drone/UAV</li>
                  <li>Mobile LiDAR</li>
                  <li>GPR</li>
                  <li>Under Ground Water Detection</li>
                  <li>Geo Technical & Physical</li>
                </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>       


          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Services;
