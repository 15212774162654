import React, {useEffect} from 'react'
import './Banner.css'

import { IoCall } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";

import BgImage from '../../assets/images/bg/background.webp'
import { Parallax, Background } from 'react-parallax'

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();


const Banner = () => {
             useEffect(() => {
             AOS.init();
            }, []);


  return (
    <div className='banner-bg'>
      <Parallax strength={300}>
        <Background className="custom-bg">
            <img src={BgImage} alt='backgroundimage'/>
        </Background>
           <div className='banner-content' data-aos="fade-up" data-aos-duration="500">
           <h1>GEOSPATIAL - ENGINEERING - SURVEYING
           </h1>
           <p>"To carryout research and development for the successfully
completion of Geospatial Projects. Core competence is
Our main value to provide cutting edge technological 
solutions to our customer".</p>
           </div>
    
        <div className='banner-contact' data-aos="fade-up" data-aos-duration="1000">

              <a href="tel:7845174301">
                <div className='banner-contact-btn'>
                  <IoCall className='con-icon' /><p>91+ 784-517-4301</p>
                </div>
              </a>

              <a href="mailto:info@geostorm.in">
                <div className='banner-contact-btn'>
                  <IoMailOutline className='con-icon'/><p>info@geostorm.in</p>
                </div>
              </a>
          
              </div>
             
              

     
     </Parallax>
    </div>
    
  )
}

export default Banner
