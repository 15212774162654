import React, {useEffect} from 'react'
import './GalleryPage.css'
import { Row, Col, Container } from 'react-bootstrap'

import Header from '../../components/header/Header';
import Details from '../../components/details/Details'
import Footer from '../../components/footer/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const GalleryPage = () => { useEffect(() => {
  AOS.init()
}, [])

  return (
    <div id='gallery'>
      <Header/>

       <Container>
        <Row>
          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/1.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/2.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/3.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/4.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/5.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/6.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/7.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/8.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/9.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/10.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/11.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/12.jpg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/13.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/14.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/15.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/16.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/17.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/18.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/19.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/20.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/21.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/22.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/23.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/24.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

          <Col md={6} lg={3} data-aos="zoom-out-up" data-aos-duration="800">
          <img className='images' src={require('../../assets/images/photos/25.jpeg')} alt="geo storm tech gallery photos"/>
          </Col>

        </Row>
       </Container>

       <div className='gallery-gap'></div>
       
      <Details/>
      <Footer/>
    </div>
  )
}

export default GalleryPage