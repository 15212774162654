import React, {useEffect} from 'react'
import './SurveyingPage.css'
import { Col, Container, Row } from 'react-bootstrap'

import Header from '../../components/header/Header'
import Details from '../../components/details/Details'
import Footer from '../../components/footer/Footer'

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const SurveyingPage = () => { useEffect(() => {
  AOS.init();
 }, []);

  return (
    <div>
      <Header/>
      <div id='about-content'>
      <h1 className='title'>Surveying</h1>
       
       <Container>
         <Row>
           

           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/dgps.jpg')} alt="dgps" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col  md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>DGPS</h2>
           <p>The Differential Global Positioning System (DGPS) service transmits correction signals to GPS navigation equipment on board vessels. The DGPS service improves the accuracy of your GPS position and the quality of the signal.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/total-station.jpeg')} alt="total-station" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Total Station</h2>
           <p>Total stations are the primary survey instrument used in mining surveying. A total station is used to record the absolute location of the tunnel walls, ceilings (backs), and floors as the drifts of an underground mine are driven.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/uav-drone.jpg')} alt="drone-uav" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Drone/UAV</h2>
           <p>A drone survey refers to the use of a drone, or unmanned aerial vehicle (UAV), to capture aerial data with downward-facing sensors, such as RGB or multispectral cameras, and LIDAR payloads.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/mobile-lidar.png')} alt="mobile-lidar" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Mobile LiDAR</h2>
           <p>It's essentially a remote sensing method that uses rapid light pulses to map out the surface of the earth. It uses the pulse from a laser to collect measurements to create 3D models and maps of objects and environments.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/gpr.jpg')} alt="gpr" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>GPR</h2>
           <p>A Ground Penetrating Radar Survey, commonly referred to as a GPR survey, is a geophysical locating technique that makes use of radio waves to take images of entities below ground level without digging up the soil.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/u-w-d.jpg')} alt="underground-water-detection" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Underground Water Detection</h2>
           <p>Groundwater exploration is the investigation of underground formations to understand the hydrologic cycle, know the groundwater quality, and identify the nature, number and type of aquifers.
           </p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/survey/geotechnical.jpeg')} alt="geo-technical" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Geo Technical & Physical</h2>
           <p>A geotechnical investigation is primarily concerned with testing the physical properties of the soil, to provide recommendations for foundation requirements, excavation stability, drainage and buried concrete design.</p>
           </div>
           </Col>
           <div className='gap'></div>


         </Row>
       </Container>

       </div>
        
      <Details/>
      <Footer/>
    </div>
  )
}

export default SurveyingPage