import React, {useEffect} from 'react'
import './GeospatialPage.css'

import Header from '../../components/header/Header'
import Details from '../../components/details/Details'
import Footer from '../../components/footer/Footer'

import { Col, Container, Row } from 'react-bootstrap'

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const GeospatialPage = () => { useEffect(() => {
    AOS.init();
   }, []); 

  return (
    <div>
      <Header/>

      <div id='about-content'>
      <h1 className='title'>Geospatial</h1>

      <Container>
         <Row>
           

           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/photogrammetry.jpg')} alt="photogrammetry" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col  md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Photogrammetery</h2>
           <p>Photogrammetery is the science and technology of making measurements from photographs.The input to photogrammetry is photographs, and the output is typically a map, a drawing, a measurement, or a 3D model of some real-world object or scene.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/lidar.jpg')} alt="LiDAR" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>LiDAR</h2>
           <p>Light detection and ranging - is a remote sensing method used to examine the surface of the earth.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/mobile-lidar.png')} alt="mobile LiDAR"data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content'data-aos="fade-left" data-aos-duration="1000">
           <h2>Mobile LiDAR</h2>
           <p>Mobile LiDAR is used to map and survey corridors from the street level with high accuracy.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/gis.jpeg')} alt="GIS" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>GIS</h2>
           <p>A geographic information system - is a computer system that analyzes and displays geographically referenced information.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/cad.jpg')} alt="CAD" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>CAD</h2>
           <p>Computer-aided design - is the use of computer based software to aid in design processes.</p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/orthophoto.jpg')} alt="orthophoto" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Orthophoto</h2>
           <p>An Orthophoto is an aerial image that has been geometrically corrected (ortho rectified) so that the image is uniform from edge to edge.
           </p>
           </div>
           </Col>
           <div className='gap'></div>


           <Col md={6} lg={6}>
           <img className='imagess' src={require('../../assets/images/services/geospatial/Cadastral-mapping.jpg')} alt="cadastral mapping" data-aos="fade-right" data-aos-duration="1000"/>
           </Col>
           <Col md={6} lg={6}>
           <div className='content' data-aos="fade-left" data-aos-duration="1000">
           <h2>Cadastral mapping</h2>
           <p>A cadastral mapping is a scaled map that shows the measurements, survey boundaries, ownership borders and a unique identifier for every unit of real estate property within a city.</p>
           </div>
           </Col>
           <div className='gap'></div>

         </Row>
       </Container>
       </div>

       <Details/>
       <Footer/>
    </div>
  )
}

export default GeospatialPage