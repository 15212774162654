import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>
        <Navbar expand="lg" className="fixed-top navbar-css" data-bs-theme="light" >
        <Container>
        <Navbar.Brand>
        <img src = {require ('../../assets/images/logo/geostormlogo.png')}
             alt='logo' className= "logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className='all-links'>

          <Nav.Link as = {Link} to = "/home"
            className='links linkss'>HOME</Nav.Link>

            <NavDropdown className='links linkss'title="SERVICES" id="basic-nav-dropdown">

              <NavDropdown.Item as = {Link} to = "/geospatial"
              className='text-center links linkss'>GEOSPATIAL</NavDropdown.Item>

              <NavDropdown.Item as = {Link} to = "/engineering" className='text-center links linkss'>ENGINEERING</NavDropdown.Item>

              <NavDropdown.Item as = {Link} to = "/surveying"
              className='text-center links linkss'>SURVEYING</NavDropdown.Item>
              
              </NavDropdown>

            <Nav.Link as = {Link} to = "/gallery"
            className='links linkss'>GALLERY</Nav.Link>

            <Nav.Link as = {Link} to = "/aboutus"
            className='links linkss'>ABOUT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default Header