import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/SrollToTop';


import Home from './pages/Home';
import GalleryPage from './pages/gallery/GalleryPage';
import AboutPage from './pages/about page/AboutPage';
import GeospatialPage from './pages/geospatial/GeospatialPage';
import SurveyingPage from './pages/surveying/SurveyingPage';
import EngineeringPage from './pages/engineering/EngineeringPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
  
  
           <Route index element = {<Home/>}/>
           <Route path="/home" element={<Home/>} />
           <Route path="/home" element={<Home/>} />
           <Route path="/geospatial" element={<GeospatialPage/>} />
           <Route path="/engineering" element={<EngineeringPage/>} />
           <Route path="/surveying" element={<SurveyingPage/>} />
           <Route path="/gallery" element={<GalleryPage/>} />
           <Route path="/aboutus" element={<AboutPage/>} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
