import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer-css'>
      <footer> &copy; 2023 | Geo Storm Tech, All rights reserved.</footer>
      <p className='main-footer'>website developed by JAI SHANTH</p>
    </div>
  )
}

export default Footer